.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 2px solid #DDDEDE;
  content: "";
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  margin: 0 15px;
}

.symptom-selector nz-option-container div .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.symptom-selector nz-option-container div .cdk-virtual-scroll-content-wrapper nz-option-item {
  flex: 1 1 151px;
}

.ant-avatar-string {
  position: static;
}

.ant-spin-dot-item {
  background: var(--aiomed-night-blue-color);
}

.custom-progress {
  transform: rotate(180deg) !important;
}

.ant-spin-blur::after {
  opacity: 0.8 !important;
  pointer-events: auto;
}

.aiomed-avatar {
  color: #A2AFC3;
  background-color: #ECEEF2;
}
.aiomed-avatar span {
  padding-left: 0 !important;
  text-transform: uppercase;
}

i[nz-icon] {
  color: #B3B5B5;
}
i[nz-icon][severity=critical] {
  color: #E7424A;
}
i[nz-icon][severity=major] {
  color: #f28c15;
}
i[nz-icon][severity=info] {
  color: #6BB795;
}

button.btn-def {
  box-shadow: inset 0 0 0 1px #AFB7C7 !important;
}
button.btn-def:hover {
  box-shadow: inset 0 0 0 1px #758198 !important;
}

body {
  display: flex;
  justify-content: center;
}

.container {
  padding: 0 16px 16px 16px;
  min-width: 360px;
  width: 100%;
  background: var(--aiomed-white-color);
}

button.btn-prime {
  background: #263558 !important;
  color: var(--aiomed-white-color) !important;
}
button.btn-prime:hover {
  background: rgb(67, 83, 122) !important;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}
.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.9);
}

section.entities-table {
  padding: 19px 22px 10px 0;
  position: relative;
  min-height: 670px;
  text-align: right;
  max-width: 100%;
}
section.entities-table nz-table {
  padding-top: 10px;
}
section.entities-table nz-table thead tr th {
  padding: 8.5px 16px;
  text-align: left;
  font-weight: bold;
  color: #758198;
  font-size: 12px;
}
section.entities-table nz-table thead tr th svg {
  color: #758198;
}
section.entities-table nz-table thead tr th.item-name {
  padding-left: 44px;
}
section.entities-table nz-table tbody tr {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: zoom-in;
  border-bottom: 1px solid #f4f4f8;
}
section.entities-table nz-table tbody tr td {
  padding-left: 0;
  padding-right: 0;
}
section.entities-table nz-table tbody tr td:first-child div {
  padding-left: 17px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: left;
}
section.entities-table nz-table tbody tr td:last-child div {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
section.entities-table nz-table tbody tr td div {
  color: #252a36;
  font-weight: 400;
  font-size: 14px;
  height: 90px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 16px;
}
section.entities-table nz-table tbody tr td div i {
  font-size: 15px;
}
section.entities-table nz-table tbody tr td div span.item-value {
  padding: 0 2px;
}
section.entities-table nz-table tbody tr td div span.item-small {
  margin-top: 3px;
  margin-left: 2px;
  font-size: 9px;
}
section.entities-table nz-table tbody tr td div span.item-degree {
  margin-left: 2px;
  margin-bottom: 6px;
  font-size: 9px;
}
section.entities-table nz-table tbody tr td div ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 430px;
}
section.entities-table nz-table tbody tr td div ul.custom-symptoms li {
  height: 21px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  margin: 0 8px 8px 0;
}
section.entities-table nz-table tbody tr td div ul.custom-symptoms li.symptom {
  border: 1px solid #778196;
  background: #FFFFFF 0 0 no-repeat padding-box;
}
section.entities-table nz-table tbody tr td div ul.custom-symptoms li.condition {
  border: 1px solid #778196;
  background: #758198 0 0 no-repeat padding-box;
  color: #FFFFFF;
}
section.entities-table nz-table tbody tr td div.old-status {
  color: #B3B5B5 !important;
}
section.entities-table nz-table tbody tr td div.old-status i {
  color: #B3B5B5 !important;
  animation: none;
}
section.entities-table nz-table tbody tr td div.table-name {
  justify-content: flex-start;
  align-items: center;
  padding-left: 17px;
}
section.entities-table nz-table tbody tr td div.table-name img {
  border-radius: 50%;
}
section.entities-table nz-table tbody tr td div.table-name span {
  padding-left: 14px;
}
section.entities-table nz-table tbody tr[severity=critical] td div {
  background: var(--table-red-color);
}
section.entities-table nz-table tbody tr[severity=major] td div {
  background: var(--table-yellow-color);
}

.ant-table-content {
  height: 555px;
}

.ant-table-tbody {
  position: relative !important;
  margin-top: 30px !important;
}

.ant-table-container .scroll-content {
  min-height: 400px !important;
}

.entities-table__title {
  display: flex;
  justify-content: flex-start;
  font: normal normal bold 16px/24px Lato;
  color: #252A36;
  text-transform: uppercase;
}
.entities-table__search-pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 6px;
}
.entities-table__search-pagination-container .ant-input-affix-wrapper {
  border: 1px solid #DBDCDD;
  border-radius: 11px;
  padding: 0 11px;
  height: 28px;
  width: 336px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: ease-in-out 0.3s;
  width: 100%;
  display: flex;
  justify-content: center;
}
.loading__container i {
  font-size: 40px;
}

section.aiomed-sider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 21px;
}
section.aiomed-sider ul.aiomed-sider-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.aiomed-sider ul.aiomed-sider-menu li {
  width: 64px;
  height: 56px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  transition: ease-in-out 0.1s;
}
section.aiomed-sider ul.aiomed-sider-menu li.active {
  background: #535D77 0 0 no-repeat padding-box;
  border-radius: 18px;
  transition: ease-in-out 0.1s;
}
section.aiomed-sider ul.aiomed-sider-menu li.active svg {
  fill: white;
  color: white;
}
section.aiomed-sider ul.aiomed-sider-menu li svg {
  font-size: 45px;
  fill: #A8AEBC;
  color: #A8AEBC;
}
section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-count, section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-dot, section.aiomed-sider ul.aiomed-sider-menu li .ant-badge .ant-scroll-number-custom-component {
  top: 8px;
}
section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-count {
  width: 16px;
  height: 16px;
  padding: 0;
  font-size: 8px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
section.aiomed-sider div.aiomed-sider-menu-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.aiomed-sider div.aiomed-sider-menu-user img, section.aiomed-sider div.aiomed-sider-menu-user span {
  width: 25px;
  height: 25px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  display: flex;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  background: #ccc;
}
section.aiomed-sider div.aiomed-sider-menu-user div {
  font: normal normal normal 12px/24px Lato;
  color: #FFFFFF;
}

.menu {
  position: fixed;
  width: 260px;
  transition: ease-in-out 0.1s;
}

.menu-collapsed {
  width: 96px;
  transition: ease-in-out 0.1s;
}