section.entities-table {
  padding: 19px 22px 10px 0;
  position: relative;
  min-height: 670px;
  text-align: right;
  max-width: 100%;

  nz-table {

    padding-top: 10px;

    thead {
      tr {
        th {
          padding: 8.5px 16px;
          text-align: left;
          //text-transform: uppercase;
          font-weight: bold;
          color: #758198;
          font-size: 12px;

          svg {
            color: #758198;
          }
        }

        th.item-name {
          padding-left: 44px;
        }
      }
    }

    tbody {
      tr {
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: zoom-in;
        border-bottom: 1px solid #f4f4f8;

        td {
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            div {
              padding-left: 17px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              justify-content: left;
            }
          }

          &:last-child {
            div {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          div {
            color: #252a36;
            font-weight: 400;
            font-size: 14px;
            height: 90px;
            display: flex;
            //justify-content: center;
            align-items: center;
            text-transform: capitalize;
            padding-left: 16px;

            i {
              font-size: 15px;
            }

            span.item-value {
              padding: 0 2px;
            }

            span.item-small {
              margin-top: 3px;
              margin-left: 2px;
              font-size: 9px;
            }

            span.item-degree {
              margin-left: 2px;
              margin-bottom: 6px;
              font-size: 9px;
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              display: flex;
              flex-wrap: wrap;
              max-width: 430px;
            }

            ul.custom-symptoms {
              li {
                height: 21px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: 13px;
                margin: 0 8px 8px 0;
              }

              li.symptom {
                border: 1px solid #778196;
                background: #FFFFFF 0 0 no-repeat padding-box;
              }

              li.condition {
                border: 1px solid #778196;
                background: #758198 0 0 no-repeat padding-box;
                color: #FFFFFF;
              }
            }
          }

          div.old-status {
            color: #B3B5B5 !important;

            i {
              color: #B3B5B5 !important;
              animation: none;
            }
          }


          div.table-name {
            justify-content: flex-start;
            align-items: center;
            padding-left: 17px;

            img {
              border-radius: 50%;
            }

            span {
              padding-left: 14px;
            }
          }
        }

        &[severity="critical"] {
          td {
            div {
              background: var(--table-red-color);
            }
          }

        }

        &[severity="major"] {
          td {
            div {
              background: var(--table-yellow-color);
            }
          }
        }
      }
    }
  }
}

.ant-table-content {
  height: 555px;
}

.ant-table-tbody {
  position: relative !important;
  margin-top: 30px !important;
}

.ant-table-container {
  .scroll-content {
    min-height: 400px !important;
  }
}

.entities-table {
  &__title {
    display: flex;
    justify-content: flex-start;
    font: normal normal bold 16px/24px Lato;
    color: #252A36;
    text-transform: uppercase;
  }

  &__search-pagination-container {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 6px;

    .ant-input-affix-wrapper {
      border: 1px solid #DBDCDD;
      border-radius: 11px;
      padding: 0 11px;
      height: 28px;
      width: 336px;
    }
  }
}


