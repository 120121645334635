.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 2px solid #DDDEDE;
  content: '';
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  margin: 0 15px;
}

.symptom-selector {
  nz-option-container {
    div {
      .cdk-virtual-scroll-content-wrapper {
        display: flex;
        flex-wrap: wrap;

        nz-option-item {
          flex: 1 1 151px;
        }
      }
    }
  }
}

.ant-avatar-string {
  position: static;
}


.ant-spin-dot-item {
  background: var(--aiomed-night-blue-color);
}

.custom-progress {
  transform: rotate(180deg) !important;
}

.ant-spin-blur::after {
  opacity: 0.80 !important;
  pointer-events: auto;
}

.aiomed-avatar {
  color: #A2AFC3;
  background-color: #ECEEF2;

  span {
    padding-left: 0 !important;
    text-transform: uppercase;
  }
}

i[nz-icon] {
  color: #B3B5B5;

  &[severity="critical"] {
    color: #E7424A;
    //animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  }

  &[severity="major"] {
    color: #f28c15;
  }

  &[severity="info"] {
    color: #6BB795;
  }
}

button.btn-def {
  box-shadow: inset 0 0 0 1px #AFB7C7 !important;

  &:hover {
    box-shadow: inset 0 0 0 1px #758198 !important;
  }
}

body {
  display: flex;
  justify-content: center;
}

.container {
  padding: 0 16px 16px 16px;
  min-width: 360px;
  width: 100%;
  //height: 100vh;
  background: var(--aiomed-white-color);
}

button.btn-prime {
  background: #263558 !important;
  color: var(--aiomed-white-color) !important;

  &:hover {
    background: rgba(67, 83, 122, 1) !important;
  }
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.ant-modal-mask {
  background-color: rgb(100%, 100%, 100%, 0.9);
}
